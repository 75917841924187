import { getProductDetails } from "./ProductAPI";

export const addToCart = (productId, quantity) => {
    const cartItems = JSON.parse(localStorage.getItem('cartItems'));
    if(cartItems){
        const foundItem = cartItems.find((item) => item.product_id === productId);
        if(foundItem){
            const updatedCart = cartItems.map((item) => {
                return item.product_id === productId ? { ...item, quantity: item.quantity + quantity } : item;
            });
            localStorage.setItem(`cartItems`, JSON.stringify(updatedCart));
        }else{
            const cartNewItem = {
                product_id: productId,
                quantity: quantity,
                user_id: 'guest'
            }
            localStorage.setItem(`cartItems`, JSON.stringify([...cartItems, cartNewItem]));
        }
    }else{
        const cartNewItem = [{
            product_id: productId,
            quantity: quantity,
            user_id: 'guest'
        }]
        const stringifiedArray = JSON.stringify(cartNewItem);
        localStorage.setItem('cartItems', stringifiedArray);
    }
};

export const getItemFromCart = () => {
    const cartItems = JSON.parse(localStorage.getItem('cartItems'));
    const cartItemsList = [];
    if(cartItems){
        cartItemsList = cartItems.map((item) => {
            const productDetails = getProductDetails(item.product_id);
            if (productDetails) {
                return {
                    productId: productDetails.id,
                    quantity: item.quantity,
                    image: productDetails.image,
                    title: productDetails.title,
                    price: productDetails.price
                };
            }
        });
    }
    return cartItemsList;
}