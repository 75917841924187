import React from 'react';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import { FaInstagram, FaFacebook, FaLinkedin, FaLocationArrow, FaMobileAlt } from "react-icons/fa";

const footerImage = {
    backgroundImage: `url(https://shopsy-tcj.netlify.app/assets/footer-pattern-mGVwO-y6.jpg)`,
    backgroundPosition: "center bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    width: "100%"
}

const Footer = () => {
    return (
        <div className="text-white" style={footerImage}>
            <div className="container">
                <div data-aos="zoom-in" className="grid md:grid-cols-3 pb-44 pt-5 aos-init">
                    <div className="py-8 px-4">
                        <h1 className="sm:text-3xl text-xl font-bold sm:text-left text-justify mb-3 flex items-center gap-3">
                            <img src={logo} alt="" className="max-w-[50px]" />
                            SHOPCY
                        </h1>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum in beatae ea recusandae blanditiis veritatis.</p>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-3 col-span-2 md:pl-10">
                        <div>
                            <div className="py-8 px-4">
                                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">Quick Links</h1>
                                <ul className="flex flex-col gap-3">
                                    <li className="cursor-pointer hover:text-primary hover:translate-x-1 duration-300 text-gray-200">
                                        <Link to={'/'}>
                                            <span>Home</span>
                                        </Link>
                                    </li>
                                    <li className="cursor-pointer hover:text-primary hover:translate-x-1 duration-300 text-gray-200">
                                        <Link to={'/about'}>
                                            <span>About</span>
                                        </Link>
                                    </li>
                                    <li className="cursor-pointer hover:text-primary hover:translate-x-1 duration-300 text-gray-200">
                                        <Link to={'/contact'}>
                                            <span>Contact</span>
                                        </Link>
                                    </li>
                                    <li className="cursor-pointer hover:text-primary hover:translate-x-1 duration-300 text-gray-200">
                                        <Link to={'/blogs'}>
                                            <span>Blog</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="py-8 px-4">
                                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">Privacy Policy</h1>
                                <ul className="flex flex-col gap-3">
                                    <li className="cursor-pointer hover:text-primary hover:translate-x-1 duration-300 text-gray-200">
                                        <span>Privacy Policy</span>
                                    </li>
                                    <li className="cursor-pointer hover:text-primary hover:translate-x-1 duration-300 text-gray-200">
                                        <span>Return Policy</span>
                                    </li>
                                    <li className="cursor-pointer hover:text-primary hover:translate-x-1 duration-300 text-gray-200">
                                        <span>Cookie Policy</span>
                                    </li>
                                    <li className="cursor-pointer hover:text-primary hover:translate-x-1 duration-300 text-gray-200">
                                        <span>Terms and Conditions</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center gap-3 mt-6">
                                <a href="https://www.instagram.com/">
                                    <FaInstagram className="text-3xl hover:text-primary" />
                                </a>
                                <a href="https://www.facebook.com/">
                                    <FaFacebook className="text-3xl hover:text-primary" />
                                </a>
                                <a href="https://www.linkedin.com/">
                                    <FaLinkedin className="text-3xl hover:text-primary" />
                                </a>
                            </div>
                            <div className="mt-6">
                                <div className="flex items-center gap-3">
                                    <FaLocationArrow />
                                    <p>Ahmedabad, Gujarat</p>
                                </div>
                                <div className="flex items-center gap-3 mt-3">
                                    <FaMobileAlt />
                                    <p>+91 9909817921</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer