import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Products from './product/Products';
import Testimonial from './Testimonial';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
import { Link } from 'react-router-dom';
import { FaStar } from "react-icons/fa";
import { GrSecure } from "react-icons/gr";
import { IoFastFood } from "react-icons/io5";
import { GiFoodTruck } from "react-icons/gi";
import { BiSolidOffer } from "react-icons/bi";
import WinterSaleOffer from '../../assets/offers/winter-sale-offer.jpg';

const imageList = [
    {
        id: 1,
        image: "https://shopsy-tcj.netlify.app/assets/women-NhG2D3pl.png",
        title: "Upto 50% off on all Man`s Wear",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse ea provident, maxime, incidunt sed obcaecati sunt molestias quidem perferendis error quod ut libero ratione, omnis tempore possimus ipsum expedita saepe excepturi laborum labore. Fugiat doloribus, magnam illo doloremque aliquam culpa inventore eligendi consequuntur maxime sint quos, ut impedit. Cum, autem.",
        link: '/offers/upto-50%-off-on-all-mans-wear'
    },
    {
        id: 2,
        image: "https://shopsy-tcj.netlify.app/assets/shopping-vpNvhQDE.png",
        title: "30% off on all Women`s Wear",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse ea provident, maxime, incidunt sed obcaecati sunt molestias quidem perferendis error quod ut libero ratione, omnis tempore possimus ipsum expedita saepe excepturi laborum labore. Fugiat doloribus, magnam illo doloremque aliquam culpa inventore eligendi consequuntur maxime sint quos, ut impedit. Cum, autem.",
        link: '/offers/50%-off-on-all-womens-wear'
    },
    {
        id: 3,
        image: "https://shopsy-tcj.netlify.app/assets/sale-cnpHUeHf.png",
        title: "70% off on all Products Sale",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse ea provident, maxime, incidunt sed obcaecati sunt molestias quidem perferendis error quod ut libero ratione, omnis tempore possimus ipsum expedita saepe excepturi laborum labore. Fugiat doloribus, magnam illo doloremque aliquam culpa inventore eligendi consequuntur maxime sint quos, ut impedit. Cum, autem.",
        link: '/offers/70%-off-on-all-products-sale'
    }
]

const productList = [
    {
        id: 1,
        image: "https://shopsy-tcj.netlify.app/assets/women-NhG2D3pl.png",
        title: "Women Ethnic",
        rating: 5,
        color: "white",
        aosDelay: "0"
    },
    {
        id: 2,
        image: "https://shopsy-tcj.netlify.app/assets/women2-wroTMLvf.jpg",
        title: "Women western",
        rating: 4.5,
        color: "Red",
        aosDelay: "200"
    },
    {
        id: 3,
        image: "https://shopsy-tcj.netlify.app/assets/women3-HFaPDX0l.jpg",
        title: "Goggles",
        rating: 4.7,
        color: "brown",
        aosDelay: "400"
    },
    {
        id: 4,
        image: "https://shopsy-tcj.netlify.app/assets/women4-zXERyOhD.jpg",
        title: "Printed T-Shirt",
        rating: 4.4,
        color: "Yellow",
        aosDelay: "600"
    },
    {
        id: 5,
        image: "https://shopsy-tcj.netlify.app/assets/women2-wroTMLvf.jpg",
        title: "Fashin T-Shirt",
        rating: 4.5,
        color: "Pink",
        aosDelay: "800"
    }
];

const bannerImage = {
    backgroundImage: `url(https://shopsy-tcj.netlify.app/assets/orange-pattern-w2kUn2Xh.jpg)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    width: "100%"
}

const bestProduct = [
    {
        id: 1,
        image: "https://shopsy-tcj.netlify.app/assets/shirt-cwf9SKdB.png",
        title: "Women Ethnic",
        slug: 'women-ethnic',
        price: "17.99"
    },
    {
        id: 2,
        image: "https://shopsy-tcj.netlify.app/assets/shirt2-XQzG6elp.png",
        title: "Printed shirt",
        slug: 'printed-shirt',
        price: "20.99"
    },
    {
        id: 3,
        image: "https://shopsy-tcj.netlify.app/assets/shirt3-HwQ10bVo.png",
        title: "Women shirt",
        slug: 'Women shirt',
        price: "19.99"
    }
];

const Home = () => {

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: "ease-in-out",
        pauseOnHover: false,
        pauseOnFocus: true
    };

    return (
        <>
            <Navbar />
            <div className='relative overflow-hidden min-h-[550px] sm:min-h-[650] bg-gray-100 flex justify-center items-center dark:bg-gray-950 dark:text-white duration-200'>
                <div className='h-[700px] w-[700px] bg-primary/40 absolute -top-1/2 right-0 rounded-3xl rotate-45 -z-9'></div>
                <div className='container pb-8 sm:pb-0'>
                    <Slider {...settings}>
                        {imageList.map((data) =>(
                            <div key={data.id}>
                                <div className='grid grid-cols-1 sm:grid-cols-2 '>
                                    <div className='flex flex-col justify-center gap-4 pt-12 sm:pt-0 text-center sm:text-left order-2 sm:order-1 relative z-10'>
                                        <h1 data-aos="zoom-out" data-aos-duration="500" data-aso-once="true" className='text-5xl sm:text-6xl lg:text-7xl font-bold'>{data.title}</h1>
                                        <p data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" className='text-sm'>{data.description}</p>
                                        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
                                            <Link to={data.link} className='bg-gradient-to-r from-primary to-secondary hover:scale-105 duration-200 text-white py-2 px-4 rounded-full'>Order Now</Link>
                                        </div>
                                    </div>
                                    <div className='order-2 sm:order-1'>
                                        <div className='relative z-10'>
                                            <img data-aos="zoom-out" data-aos-duration="500" data-aso-once="true" src={data.image} alt="Hello" className='h-[300px] w-[300px] sm:h-[450px] sm:w-[450px] sm:scale-105 lg:scale-120 object-contain mx-auto' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            
            <div className='mt-12 mb-12'>
                <div className='container'>
                    <div className='text-center mb-10 max-w-[600px] mx-auto'>
                        <p data-aos="fade-up" className='text-sm text-primary'>Top Selling Products for you</p>
                        <h1 data-aos="fade-up" className='text-3xl font-bold'>Products </h1>
                        <p data-aos="fade-up" className='text-xs text-gray-400'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero soluta nobis molestias.</p>
                    </div>
                    <div>
                        <div className='grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 place-items-center gap-5'>
                            {productList.map((data) => (
                                <div key={data.id} data-aos="fade-up" data-aos-delay={data.aosDelay} className='space-y-3 aos-init aos-animate'>
                                    <img src={data.image} alt="" className='h-[250px] w-[300px] sm:h-[220px] sm:w-[150px] object-cover rounded-md' />
                                    <div>
                                        <h3 className='font-semibold'>{data.title}</h3>
                                        <p className='text-sm text-gray-600'>{data.color}</p>
                                        <div className='flex items-center gap-1'>
                                            <FaStar className='text-yellow-400' />
                                            <span>{data.rating}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-center">
                            <Link to='/products' className="text-center mt-10 cursor-pointer bg-primary text-white py-1 px-5 rounded-md">View All Button</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='min-h-[550px] flex justify-center items-center bg-gray-100 py-12 dark:bg-gray-950'>
                <div className='container'>
                    <div className="text-left mb-24">
                        <p data-aos="fade-up" className="text-sm text-primary aos-init aos-animate">Top Rated Products for you</p>
                        <h1 data-aos="fade-up" className="text-3xl font-bold aos-init aos-animate">Best Products</h1>
                        <p data-aos="fade-up" className="text-xs text-gray-400 aos-init aos-animate">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sit asperiores modi Sit asperiores modi</p>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-20 md:gap-5 place-items-center">
                        { bestProduct && bestProduct.map((item) => (
                            <div key={item.id} data-aos="zoom-in" className="rounded-2xl bg-white dark:bg-gray-800 hover:bg-black/80 dark:hover:bg-primary hover:text-white relative shadow-xl duration-300 group max-w-[300px] aos-init aos-animate">
                                <Link to={'/product/'+item.slug}>
                                    <div className="h-[100px]">
                                        <img src={item.image} alt="" className="max-w-[140px] block mx-auto transform -translate-y-20 group-hover:scale-105 duration-300 drop-shadow-md" />
                                    </div>
                                </Link>
                                <div className="p-4 text-center">
                                    <div className="w-full flex items-center justify-center gap-1">
                                        <FaStar className='text-yellow-500'/>
                                        <FaStar className='text-yellow-500'/>
                                        <FaStar className='text-yellow-500'/>
                                        <FaStar className='text-yellow-500'/>
                                        <FaStar className='text-yellow-500'/>
                                    </div>
                                    <h1 className="text-xl font-bold">{item.title}</h1>
                                    <p className="text-gray-500 group-hover:text-white duration-300 text-sm line-clamp-2">{item.discription}</p>
                                    <div className='flex items-center justify-between'>
                                        <h3 className='text-xl font-medium py-1 mt-4 px-4'>${item.price}</h3>
                                        <button className="bg-primary hover:scale-105 duration-300 text-white py-1 mt-4 px-4 rounded-full group-hover:bg-white group-hover:text-primary">Order Now</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* <div data-aos="zoom-in" className="rounded-2xl bg-white dark:bg-gray-800 hover:bg-black/80 dark:hover:bg-primary hover:text-white relative shadow-xl duration-300 group max-w-[300px] aos-init aos-animate">
                            <div className="h-[100px]">
                                <img src="https://shopsy-tcj.netlify.app/assets/shirt-cwf9SKdB.png" alt="" className="max-w-[140px] block mx-auto transform -translate-y-20 group-hover:scale-105 duration-300 drop-shadow-md" />
                            </div>
                            <div className="p-4 text-center">
                                <div className="w-full flex items-center justify-center gap-1">
                                    <FaStar className='text-yellow-500'/>
                                    <FaStar className='text-yellow-500'/>
                                    <FaStar className='text-yellow-500'/>
                                    <FaStar className='text-yellow-500'/>
                                    <FaStar className='text-yellow-500'/>
                                </div>
                                <h1 className="text-xl font-bold">Casual Wear</h1>
                                <p className="text-gray-500 group-hover:text-white duration-300 text-sm line-clamp-2">lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <button className="bg-primary hover:scale-105 duration-300 text-white py-1 px-4 rounded-full mt-4 group-hover:bg-white group-hover:text-primary">Order Now</button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="min-h-[550px] flex justify-center items-center py-12 sm:py-0">
                <div className="container">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 items-center">
                        <div data-aos="zoom-in" className="aos-init aos-animate">
                            <img src={WinterSaleOffer} alt="" className="max-w-[400px] h-[350px] w-full mx-auto drop-shadow-[-10px_10px_12px_rgba(0,0,0,1)] object-cover" />
                        </div>
                        <div className="flex flex-col justify-center gap-6 sm:pt-0">
                            <h1 data-aos="fade-up" className="text-3xl sm:text-4xl font-bold aos-init aos-animate">Winter Sale upto 50% Off</h1>
                            <p data-aos="fade-up" className="text-sm text-gray-500 tracking-wide leading-5 aos-init aos-animate">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque reiciendis inventore iste ratione ex alias quis magni at optio</p>
                            <div className="flex flex-col gap-4">
                                <div data-aos="fade-up" className="flex items-center gap-4 aos-init aos-animate">
                                    <GrSecure className='text-4xl h-12 w-12 shadow-sm p-4 rounded-full bg-violet-100 dark:bg-violet-400' />
                                    <p>Quality Products</p>
                                </div>
                                <div data-aos="fade-up" className="flex items-center gap-4 aos-init">
                                    <IoFastFood className='text-4xl h-12 w-12 shadow-sm p-4 rounded-full bg-orange-100 dark:bg-orange-400' />
                                    <p>Fast Delivery</p>
                                </div>
                                <div data-aos="fade-up" className="flex items-center gap-4 aos-init">
                                    <GiFoodTruck className='text-4xl h-12 w-12 shadow-sm p-4 rounded-full bg-green-100 dark:bg-green-400' />
                                    <p>Easy Payment method</p>
                                </div>
                                <div data-aos="fade-up" className="flex items-center gap-4 aos-init">
                                    <BiSolidOffer className='text-4xl h-12 w-12 shadow-sm p-4 rounded-full bg-yellow-100 dark:bg-yellow-400' />
                                    <p>Get Offers</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div data-aos="zoom-in" className="bg-gray-100 dark:bg-gray-800 text-white aos-init aos-animate">
                <div className="container backdrop-blur-sm py-10" style={bannerImage}>
                    <div className="space-y-6 max-w-xl mx-auto">
                        <h1 className="text-2xl !text-center sm:text-left sm:text-4xl font-semibold">Get Notified About New Products</h1>
                        <input data-aos="fade-up" type="text" placeholder="Enter your email" className="w-full p-3 text-primary aos-init aos-animate" />
                    </div>
                </div>
            </div>

            <Testimonial />
            <Footer />
        </>
    )
}

export default Home