import React from 'react'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'

const Contact = () => {
    return (
        <>
            <Navbar />
            <Footer />
        </>
    )
}

export default Contact