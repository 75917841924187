import React, { useState,useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';


const ProductReviews = () => {
    const [stripePromise, setStripePromise] = useState(null);
    const [error, setError] = useState(null);
    const stripe = useStripe();
    const elements = useElements();
    useEffect(() => {
        const getStripePromise = async () => {
          const stripePromise = await loadStripe('pk_test_51PM4xYETsMhZUIRWMnYtbANCwsviULE9ARcLGcA3gzL7bp11FdCtCLCte3o8M6SzRv9xc0WYz30ss34kZeOsL6Cf00bKAgp2vS');
          setStripePromise(stripePromise);
        };
    
        getStripePromise();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!stripePromise) {
          console.error('Stripe is not loaded yet');
          return;
        }
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: {
                number: '4242424242424242',
                exp_month: 8,
                exp_year: 2026,
                cvc: '314',
            }
        });
    
        // if (error) {
        //   setError(error.message);
        //   return;
        // }
    
        const token = paymentMethod;
        // console.log(paymentMethod);
    
        // // Send the token to your backend to create a charge or PaymentIntent
        // try {
        //   const response = await fetch('/api/charge', {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ token }),
        //   });
    
        //   const data = await response.json();
    
        //   if (data.error) {
        //     setError(data.error.message);
        //     return;
        //   }
    
        //   console.log('Payment successful!', data);
        //   // Handle successful payment (e.g., display confirmation message)
        // } catch (error) {
        //     console.log(error);
        // //   console.error('Error creating charge:', error);
        //   setError('An error occurred. Please try again.');
        // }
    };

    return (
        <div className='flex h-screen justify-center items-center'>
             <form onSubmit={handleSubmit}>
                {error && <div className="error">{error}</div>}
                <Elements stripe={stripePromise}>
                    <CardElement options={{ style: { base: { fontSize: '16px' } } }} />
                </Elements>
                <button type="submit" disabled={!stripePromise}>
                    Pay
                </button>
            </form>
            {/* <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                {error && <div className="text-red-500">{error}</div>}
                <div className="flex flex-col">
                <label htmlFor="cardNumber" className="mb-2">Card Number</label>
                <input
                    max='16'
                    type="text"
                    id="cardNumber"
                    value={cardNumber}
                    onChange={handleChangeCardNumber}
                    className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                />
                </div>
                <div className="flex space-x-4">
                <div className="flex flex-col">
                    <label htmlFor="expiry" className="mb-2">Expiry (MM/YY)</label>
                    <input
                    type="text"
                    id="expiry"
                    value={expiry}
                    onChange={(e) => setExpiry(e.target.value)}
                    className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="cvc" className="mb-2">CVC</label>
                    <input
                    type="text"
                    id="cvc"
                    value={cvc}
                    onChange={(e) => setCvc(e.target.value)}
                    className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                </div>
                </div>
                <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700">
                Pay
                </button>
            </form> */}
        </div>
    )
}

export default ProductReviews