export const getProducts = () => {
    const productList = [
        {
            id: 1,
            image: "https://shopsy-tcj.netlify.app/assets/women-NhG2D3pl.png",
            title: "Women Ethnic",
            rating: 5,
            color: "white",
            aosDelay: "0"
        },
        {
            id: 2,
            image: "https://shopsy-tcj.netlify.app/assets/women2-wroTMLvf.jpg",
            title: "Women western",
            rating: 4.5,
            color: "Red",
            aosDelay: "200"
        },
        {
            id: 3,
            image: "https://shopsy-tcj.netlify.app/assets/women3-HFaPDX0l.jpg",
            title: "Goggles",
            rating: 4.7,
            color: "brown",
            aosDelay: "400"
        },
        {
            id: 4,
            image: "https://shopsy-tcj.netlify.app/assets/women4-zXERyOhD.jpg",
            title: "Printed T-Shirt",
            rating: 4.4,
            color: "Yellow",
            aosDelay: "600"
        },
        {
            id: 5,
            image: "https://shopsy-tcj.netlify.app/assets/women2-wroTMLvf.jpg",
            title: "Fashin T-Shirt",
            rating: 4.5,
            color: "Pink",
            aosDelay: "800"
        }
    ];
}

export const getProductDetails = (productId) => {
    const productList = [
        {
            id: 1,
            image: "https://shopsy-tcj.netlify.app/assets/shirt-cwf9SKdB.png",
            title: "Women Ethnic",
            price: "17.99"
        },
        {
            id: 2,
            image: "https://shopsy-tcj.netlify.app/assets/shirt2-XQzG6elp.png",
            title: "Printed shirt",
            price: "20.99"
        },
        {
            id: 3,
            image: "https://shopsy-tcj.netlify.app/assets/shirt3-HwQ10bVo.png",
            title: "women-shirt",
            price: "19.99"
        }
    ];

    const ProductDetails = productList.find((item) => item.id === productId);

    return ProductDetails;
}

export const getProductDetailsBySlug = (productId) => {
    const productList = [
        {
            id: 1,
            image: "https://shopsy-tcj.netlify.app/assets/shirt-cwf9SKdB.png",
            title: "Women Ethnic",
            slug: 'women-ethnic',
            price: "17.99"
        },
        {
            id: 2,
            image: "https://shopsy-tcj.netlify.app/assets/shirt2-XQzG6elp.png",
            title: "Printed shirt",
            slug: 'printed-shirt',
            price: "20.99"
        },
        {
            id: 3,
            image: "https://shopsy-tcj.netlify.app/assets/shirt3-HwQ10bVo.png",
            title: "Women shirt",
            slug: 'Women shirt',
            price: "19.99"
        }
    ];

    const ProductDetails = productList.find((item) => item.slug === productId);
    return ProductDetails;
}