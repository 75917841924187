import React,{ useState } from 'react'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import { Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Transition, TransitionChild } from '@headlessui/react'
import { MdClose, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { FaFilter, FaMinus, FaPlus } from "react-icons/fa";
import { HiMiniSquaresPlus } from "react-icons/hi2";

const sortOptions = [
    { name: 'Most Popular', href: '#', current: true },
    { name: 'Best Rating', href: '#', current: false },
    { name: 'Newest', href: '#', current: false },
    { name: 'Price: Low to High', href: '#', current: false },
    { name: 'Price: High to Low', href: '#', current: false },
];

const subCategories = [
    { name: 'Totes', href: '#' },
    { name: 'Backpacks', href: '#' },
    { name: 'Travel Bags', href: '#' },
    { name: 'Hip Bags', href: '#' },
    { name: 'Laptop Sleeves', href: '#' },
];

const filters = [
    {
        id: 'color',
        name: 'Color',
        options: [
            { value: 'white', label: 'White', checked: false },
            { value: 'beige', label: 'Beige', checked: false },
            { value: 'blue', label: 'Blue', checked: true },
            { value: 'brown', label: 'Brown', checked: false },
            { value: 'green', label: 'Green', checked: false },
            { value: 'purple', label: 'Purple', checked: false },
        ],
    },
    {
        id: 'category',
        name: 'Category',
        options: [
            { value: 'new-arrivals', label: 'New Arrivals', checked: false },
            { value: 'sale', label: 'Sale', checked: false },
            { value: 'travel', label: 'Travel', checked: true },
            { value: 'organization', label: 'Organization', checked: false },
            { value: 'accessories', label: 'Accessories', checked: false },
        ],
    },
    {
        id: 'size',
        name: 'Size',
        options: [
            { value: '2l', label: '2L', checked: false },
            { value: '6l', label: '6L', checked: false },
            { value: '12l', label: '12L', checked: false },
            { value: '18l', label: '18L', checked: false },
            { value: '20l', label: '20L', checked: false },
            { value: '40l', label: '40L', checked: true },
        ],
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Products = () => {
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

    return (
        <>
            <Navbar />
            <div className="">
                <div>
                    {/* Mobile filter dialog */}
                    <Transition show={mobileFiltersOpen}>
                        <Dialog className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
                            <TransitionChild enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </TransitionChild>
                            <div className="fixed inset-0 z-40 flex">
                                <TransitionChild enter="transition ease-in-out duration-300 transform" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="translate-x-full">
                                    <DialogPanel className="relative bg-white dark:bg-gray-900 dark:text-white ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                                        <div className="flex items-center justify-between px-4">
                                            <h2 className="text-lg font-medium">Filters</h2>
                                            <button type="button" className="mr-2 flex h-10 w-10 items-center justify-center rounded-md p-2 border  dark:border-white" onClick={() => setMobileFiltersOpen(false)}>
                                                <MdClose className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                        {/* Filters */}
                                        <form className="m-4 border-t-2 border-gray-300 dark:border-white">
                                            <h3 className="px-2 mt-4 text-3xl font-semibold">Main Categories</h3>
                                            <ul role="list" className="px-2 py-3 font-medium">
                                                {subCategories.map((category) => (
                                                <li key={category.name}>
                                                    <a href={category.href} className="block px-2 py-2">{category.name}</a>
                                                </li>
                                                ))}
                                            </ul>
                                            {filters.map((section) => (
                                                <Disclosure as="div" key={section.id} className="border-t border-gray-200 px-4 py-6">
                                                    {({ open }) => (
                                                        <>
                                                            <h3 className="-mx-2 -my-3 flow-root">
                                                                <DisclosureButton className="flex w-full items-center justify-between px-2 py-3">
                                                                    <span className="font-medium">{section.name}</span>
                                                                    <span className="ml-6 flex items-center">
                                                                        {open ? (
                                                                        <FaMinus className="h-5 w-5" aria-hidden="true" />
                                                                        ) : (
                                                                        <FaPlus className="h-5 w-5" aria-hidden="true" />
                                                                        )}
                                                                    </span>
                                                                </DisclosureButton>
                                                            </h3>
                                                            <DisclosurePanel className="pt-6">
                                                                <div className="space-y-6">
                                                                    {section.options.map((option, optionIdx) => (
                                                                        <div key={option.value} className="flex items-center">
                                                                            <input id={`filter-mobile-${section.id}-${optionIdx}`} name={`${section.id}[]`} defaultValue={option.value} type="checkbox" defaultChecked={option.checked} className="h-4 w-4 rounded border-gray-300 accent-primary" />
                                                                            <label htmlFor={`filter-mobile-${section.id}-${optionIdx}`} className="ml-3 min-w-0 flex-1">{option.label}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </DisclosurePanel>
                                                        </>
                                                    )}
                                                </Disclosure>
                                            ))}
                                        </form>
                                    </DialogPanel>
                                </TransitionChild>
                            </div>
                        </Dialog>
                    </Transition>

                    <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex items-baseline justify-between border-b border-gray-200 py-6">
                            <h1 className="text-4xl font-bold tracking-tight">Products</h1>
                            <div className="flex items-center">
                                <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <MenuButton className="group inline-flex justify-center text-sm font-medium hover:text-primary">Sort
                                        <MdOutlineKeyboardArrowDown className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 group-hover:text-primary" aria-hidden="true" />
                                    </MenuButton>
                                </div>

                                <Transition enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                    <MenuItems className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {sortOptions.map((option) => (
                                                <MenuItem key={option.name}>
                                                    {({ focus }) => (
                                                        <a href={option.href} className={classNames( option.current ? 'font-medium text-gray-900' : 'text-gray-500', focus ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm')}>{option.name}</a>
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </div>
                                    </MenuItems>
                                </Transition>
                                </Menu>
                                <button type="button" className="-m-2 ml-4 p-2 hover:text-gray-500 sm:ml-6 lg:hidden" onClick={() => setMobileFiltersOpen(true)}>
                                    <span className="sr-only">Filters</span>
                                    <FaFilter className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <section aria-labelledby="products-heading" className="pb-24 pt-6">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                                {/* Filters */}
                                <form className="hidden lg:block">
                                    <h3 className="sr-only">Categories</h3>
                                    <ul role="list" className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                                        {subCategories.map((category) => (
                                            <li key={category.name}>
                                                <a href={category.href}>{category.name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                    {filters.map((section) => (
                                        <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                                            {({ open }) => (
                                                <>
                                                <h3 className="-my-3 flow-root">
                                                    <DisclosureButton className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                                                    <span className="font-medium text-gray-900">{section.name}</span>
                                                    <span className="ml-6 flex items-center">
                                                        {open ? ( <FaMinus className="h-5 w-5 text-primary" aria-hidden="true" /> ) : (<FaPlus className="h-5 w-5 text-primary" aria-hidden="true" />)}
                                                    </span>
                                                    </DisclosureButton>
                                                </h3>
                                                <DisclosurePanel className="pt-6">
                                                    <div className="space-y-4">
                                                    {section.options.map((option, optionIdx) => (
                                                        <div key={option.value} className="flex items-center">
                                                            <input id={`filter-${section.id}-${optionIdx}`} name={`${section.id}[]`} defaultValue={option.value} type="checkbox" defaultChecked={option.checked} className="h-4 w-4 accent-primary/40 hover:accent-gray-600" />
                                                            <label htmlFor={`filter-${section.id}-${optionIdx}`} className="ml-3 text-sm text-gray-600">{option.label}</label>
                                                        </div>
                                                    ))}
                                                    </div>
                                                </DisclosurePanel>
                                                </>
                                            )}
                                        </Disclosure>
                                    ))}
                                </form>
                                {/* Product grid */}
                                <div className="lg:col-span-3">{/* Your content */}</div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Products