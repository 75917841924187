import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonialList = [
    {
        id: 1,
        name : "Victor",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, tempore id. Eligendi, numquam? Ullam quos quasi laborum, maiores accusantium nemo?",
        image: "https://picsum.photos/101/101",
    },
    {
        id: 2,
        name : "Satya Nadella",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, tempore id. Eligendi, numquam? Ullam quos quasi laborum, maiores accusantium nemo?",
        image: "https://picsum.photos/102/102",
    },
    {
        id: 3,
        name : "Virat Kohli",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, tempore id. Eligendi, numquam? Ullam quos quasi laborum, maiores accusantium nemo?",
        image: "https://picsum.photos/103/103",
    },
    {
        id: 4,
        name : "Sachin Tendulkar",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, tempore id. Eligendi, numquam? Ullam quos quasi laborum, maiores accusantium nemo?",
        image: "https://picsum.photos/104/104",
    },
    {
        id: 5,
        name : "Rohit Sharma",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, tempore id. Eligendi, numquam? Ullam quos quasi laborum, maiores accusantium nemo?",
        image: "https://picsum.photos/105/105",
    },
    {
        id: 6,
        name : "Sky",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, tempore id. Eligendi, numquam? Ullam quos quasi laborum, maiores accusantium nemo?",
        image: "https://picsum.photos/106/106",
    },
    {
        id: 7,
        name : "Polly",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, tempore id. Eligendi, numquam? Ullam quos quasi laborum, maiores accusantium nemo?",
        image: "https://picsum.photos/107/107",
    }
];

const Testimonial = () => {
    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        pauseOnHover: true,
        pauseOnFocus: true,
        responsive: [
            {
                breakpoint: 10000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    };

    return (
        <div className="py-10">
            <div className="container">
                <div className="text-center mb-10 max-w-[600px] mx-auto">
                    <p data-aos="fade-up" className="text-sm text-primary aos-init aos-animate">What our customers are saying</p>
                    <h1 data-aos="fade-up" className="text-3xl font-bold aos-init aos-animate">Testimonials</h1>
                    <p data-aos="fade-up" className="text-xs text-gray-400 aos-init aos-animate">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sit asperiores modi Sit asperiores modi</p>
                </div>
                <div data-aos="zoom-in" className="aos-init">
                    <Slider {...settings}>
                        {testimonialList.map((data) =>(
                            <div key={data.id} className="slick-slide slick-cloned" aria-hidden="true">
                                <div>
                                    <div className="my-6">
                                        <div className="flex flex-col gap-4 shadow-lg py-8 px-6 mx-4 rounded-xl dark:bg-gray-800 bg-primary/10 relative">
                                            <div className="mb-4">
                                                <img src={data.image} alt="" className="rounded-full w-20 h-20" />
                                            </div>
                                            <div className="flex flex-col items-center gap-4">
                                                <div className="space-y-3">
                                                    <p className="text-xs text-gray-500 dark:text-white/80">{data.description}</p>
                                                    <h1 className="text-xl font-bold text-black/80 dark:text-white">{data.name}</h1>
                                                </div>
                                            </div>
                                            <p className="text-black/20 dark:text-white/40 text-9xl font-serif absolute top-0 right-0">,,</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Testimonial