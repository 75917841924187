import React, { useState, useEffect } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Navbar from '../../layout/Navbar';
import Footer from '../../layout/Footer';
import PageLoader from '../../layout/PageLoader';
import { TiTick } from "react-icons/ti";

import { useNavigate } from 'react-router-dom';
import { IoCardSharp } from "react-icons/io5";
import { FaPaypal } from "react-icons/fa";
import { BsCash } from "react-icons/bs";

const Checkout = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const location = useLocation();
    const { grandTotal } = location.state || { grandTotal: 0 };
    const navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = useState('');
    const [openCardForm, setOpenCardForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleChange = (event) => {
        setPaymentMethod(event.target.value);
        if(event.target.value === 'payment_by_card'){
            setOpenCardForm(!openCardForm);
        }else{
            setOpenCardForm(false);
        }
    };

    const handlePayment = async (event) => {
        event.preventDefault();
        setIsLoading(!isLoading);
        setTimeout(() => setIsLoading(false), 5000);

        // setLoading(true);
        // if (!stripe || !elements) {
        //     return;
        // }
        // const cardElement = elements.getElement(CardElement);
        // try {
        //     const { token, error } = await stripe.createToken(cardElement);
        //     if (error) {
        //         setError(error.message);
        //         setLoading(false);
        //         return;
        //     }
        //     if(token){
        //         axios.post('https://shopcyadmin.black4est.co.in/api/user/charge', {"token": token.id, amount: grandTotal}).then(response => {
        //             console.log(response.data.success);
        //             if(response.data.success == true){
        //                 navigate('/order-detail');
        //             }
        //         }).catch(error => {
        //             if (error.response) {
        //                 setError(error.response.headers);
        //                 if (error.response.status === 500) {
        //                     setError('Internal Server Error: Please try again later.');
        //                 }
        //             } else if (error.request) {
        //                 setError('Error Request:', error.request);
        //             } else {
        //                 setError('Error Request:', error.message);
        //             }
        //         });
        //     }
        //     setLoading(false);
        // } catch (error) {
        //     setError(error.message);
        //     setLoading(false);
        // }
    };

    return (
        <>
        {isLoading && 
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-gray-900 bg-opacity-50 flex justify-center items-center">
            <div className="relative bg-green-500 text-white p-5 w-64 h-64 text-center rounded-full animate-pulse duration-1000">
                <h3 className='absolute text-[225px] animate-ping'><TiTick className=''/></h3>
            </div>
        </div>
        }
            <Navbar />
            <div className="container">
                <div className="my-5 bg-gray-100 grid lg:grid-cols-2">
                    <div className="px-4 p-8 bg-white">
                        <p className="text-xl font-medium">Cart Summary</p>
                        <p className="text-gray-400">Check your items. And select a suitable payment method.</p>
                        <div className="mt-8 space-y-3 rounded-lg border bg-white lg:h-[675px] overflow-auto px-2 py-4 sm:px-6">
                            <div className="flex flex-col rounded-lg bg-white sm:flex-row">
                                <img className="m-2 h-24 w-28 rounded-md border object-cover object-center" src="https://images.unsplash.com/flagged/photo-1556637640-2c80d3201be8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8c25lYWtlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="" />
                                <div className="flex w-full flex-col px-4 py-4">
                                <span className="font-semibold">Nike Air Max Pro 8888 - Super Light</span>
                                <span className="float-right text-gray-400">42EU - 8.5US</span>
                                <p className="text-lg font-bold">$138.99</p>
                                </div>
                            </div>
                            <div className="flex flex-col rounded-lg bg-white sm:flex-row">
                                <img className="m-2 h-24 w-28 rounded-md border object-cover object-center" src="https://images.unsplash.com/photo-1600185365483-26d7a4cc7519?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c25lYWtlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="" />
                                <div className="flex w-full flex-col px-4 py-4">
                                <span className="font-semibold">Nike Air Max Pro 8888 - Super Light</span>
                                <span className="float-right text-gray-400">42EU - 8.5US</span>
                                <p className="mt-auto text-lg font-bold">$238.99</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 p-8 bg-white">
                        <p className="text-xl font-medium">Payment Details</p>
                        <p className="text-gray-400">Complete your order by providing your payment details.</p>
                        <label htmlFor="email" className="mt-4 mb-2 block text-sm font-medium">Email</label>
                        <div className="relative">
                            <input type="text" id="email" name="email" className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary focus:ring-primary" placeholder="your.email@gmail.com" />
                        </div>
                        <p className="mt-5 text-lg font-medium">Payment Methods</p>
                        <div className="mt-3 grid gap-6">
                            <div className="relative">
                                <input className="peer hidden" id="payment_by_card" type="radio" name="payment_methods" value="payment_by_card" onChange={handleChange} />
                                <span className="peer-checked:border-primary absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white" checked={paymentMethod === 'payment_by_card'}></span>
                                <label className="peer-checked:border-2 peer-checked:border-primary peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4" htmlFor="payment_by_card">
                                    <IoCardSharp  className='text-xl'/>
                                    <div className="ml-5">
                                        <span className="mt-2 font-semibold">Cradit or Debit Card</span>
                                    </div>
                                </label>
                            </div>
                            <div className="relative">
                                <input className="peer hidden" id="cash_on_delivery" type="radio" name="payment_methods" value="cash_on_delivery" onChange={handleChange} />
                                <span className="peer-checked:border-primary absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white" checked={paymentMethod === 'cash_on_delivery'}></span>
                                <label className="peer-checked:border-2 peer-checked:border-primary peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4" htmlFor="cash_on_delivery">
                                    <BsCash  className='text-xl'/>
                                    <div className="ml-5">
                                        <span className="mt-2 font-semibold">Cash on Delivery</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={openCardForm ? 'duration-1000' : 'hidden'}>
                            <label htmlFor="card-holder" className="mt-4 mb-2 block text-sm font-medium">Card Holder</label>
                            <div className="relative">
                                <input type="text" id="card-holder" name="card-holder" className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-primary focus:ring-primary" placeholder="Your full name here" />
                            </div>
                            <label htmlFor="card-no" className="mt-4 mb-2 block text-sm font-medium">Card Details</label>
                            <div className="flex gap-3">
                                <div className="relative w-[70%] flex-shrink-0">
                                    <input type="text" id="card-no" name="card-no" className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary focus:ring-primary" placeholder="xxxx-xxxx-xxxx-xxxx" />
                                </div>
                                <input type="text" name="credit-expiry" className="w-[20%] rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary focus:ring-primary" placeholder="MM/YY" />
                                <input type="number" name="credit-cvc" className="w-[10%] flex-shrink-0 rounded-md border border-gray-200 px-3 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary focus:ring-primary" placeholder="CVC" min='3' max='4' />
                            </div>
                        </div>
                        <div>
                            <div className="mt-6 border-t-2 border-b-2 border-primary/40 py-2">
                                <div className="flex items-center justify-between">
                                    <p className="text-sm font-medium text-gray-900">Subtotal</p>
                                    <p className="font-semibold text-gray-900">$399.00</p>
                                </div>
                                <div className="flex items-center justify-between">
                                    <p className="text-sm font-medium text-gray-900">Shipping Charge</p>
                                    <p className="font-semibold text-gray-900">$8.00</p>
                                </div>
                                <div className="flex items-center justify-between">
                                    <p className="text-sm font-medium text-gray-900">Tax </p>
                                    <p className="font-semibold text-gray-900">$0.00</p>
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <p className="text-sm font-medium text-gray-900">Total</p>
                                <p className="text-2xl font-semibold text-gray-900">$408.00</p>
                            </div>
                        </div>
                        <button className="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white" onClick={handlePayment}>Place Order</button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Checkout