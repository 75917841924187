import React from 'react'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'

const SearchProduct = () => {
    return (
        <>
            <Navbar />
            <div className='h-20'>SearchProduct</div>
            <Footer />
        </>
    )
}

export default SearchProduct