import React, {useState, useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle';
import axios from 'axios';
import Navbar from '../../layout/Navbar';
import Footer from '../../layout/Footer';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addToCart } from '../../../services/CartAPI';
import { getProductDetailsBySlug } from '../../../services/ProductAPI';

const mainImages = [
    'https://pagedone.io/asset/uploads/1700472379.png',
    'https://pagedone.io/asset/uploads/1711622397.png',
    'https://pagedone.io/asset/uploads/1711622408.png',
    'https://pagedone.io/asset/uploads/1711622419.png',
    'https://pagedone.io/asset/uploads/1711622437.png',
];

const ProductDetail = () => {
    const [mainImage, setMainImage] = useState(mainImages[0]);
    const [productDetails, setProductDetails] = useState();
    const { productId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const changeImage = (image) => {
        setMainImage(image);
    };

    useEffect(() => {
        const fetchProducts = async () => {
            console.log(productId);
            const data = await getProductDetailsBySlug(productId);
            setProductDetails(data);
            setMainImage(productDetails.image);
        };
    
        fetchProducts();
    }, []);

    // useEffect(() => {
    //     const fetchProducts = async () => {
    //         try {
    //             const response = await axios.get('https://shopcyadmin.black4est.co.in/api/get-product-detail/'+productId);
    //             setProductDetails(response.data.items);
    //             setMainImage(productDetails.image);
    //         } catch (error) {
    //             setError(error.message);
    //         } finally {
    //             setLoading(false);
    //         }
    //     }
    //     fetchProducts();
    // }, []);

    const handleAddToCart = (productId) => {
        addToCart(productId, 1);
    
        toast.success('Added to cart!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            <Navbar />
            <section className="py-24 bg-gray-100 dark:bg-gray-900">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="slider-box w-full h-full max-lg:mx-auto mx-0">
                            <Swiper modules={[Thumbs]} spaceBetween={30} slidesPerView={1} className="main-slide-carousel relative mb-6">
                            {mainImages.map((src, index) => (
                                <SwiperSlide key={index}>
                                    <div className="block">
                                        <img src={mainImage ? mainImage : src} alt="Summer Travel Bag image" className="max-lg:mx-auto" />
                                    </div>
                                </SwiperSlide>
                            ))}
                            </Swiper>
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={3}
                                loop={true}
                                className="nav-for-slider"
                            >
                            {mainImages.map((src, index) => (
                                <SwiperSlide key={index} className="thumbs-slide">
                                    <img src={src} onClick={() => changeImage(src)} alt="Summer Travel Bag image" className={`cursor-pointer rounded-xl transition-all duration-500 hover:border-2 hover:border-primary ${mainImage === src ? 'border-2 border-primary' : ''}`} />
                                </SwiperSlide>
                            ))}
                            </Swiper>
                        </div>
                        <div className="flex justify-center items-center">
                            <div className="pro-detail w-full max-lg:max-w-[608px] lg:pl-8 xl:pl-16 max-lg:mx-auto max-lg:mt-8">
                            <div className="flex items-center justify-between gap-6 mb-6">
                                <div className="text">
                                <h2 className="font-manrope font-bold text-3xl leading-10 mb-2">{productDetails && productDetails.title}</h2>
                                <p className="font-normal text-base">ABS LUGGAGE</p>
                                </div>
                                <button className="group transition-all duration-500 p-0.5">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle className="fill-primary-40 transition-all duration-500 group-hover:fill-primary/20" cx="30" cy="30" r="30" fill="" />
                                    <path className="stroke-primary transition-all duration-500 group-hover:stroke-primary" d="M21.4709 31.3196L30.0282 39.7501L38.96 30.9506M30.0035 22.0789C32.4787 19.6404 36.5008 19.6404 38.976 22.0789C41.4512 24.5254 41.4512 28.4799 38.9842 30.9265M29.9956 22.0789C27.5205 19.6404 23.4983 19.6404 21.0231 22.0789C18.548 24.5174 18.548 28.4799 21.0231 30.9184M21.0231 30.9184L21.0441 30.939M21.0231 30.9184L21.4628 31.3115" stroke="" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                </button>
                            </div>
                            <div className="flex flex-col min-[400px]:flex-row min-[400px]:items-center mb-8 gap-y-3">
                                <div className="flex items-center">
                                <h5 className="font-manrope font-semibold text-2xl leading-9  ">$ {productDetails && productDetails.price} </h5>
                                <span className="ml-3 font-semibold text-lg dark:text-primary">30% off</span>
                                </div>
                                    <svg className="mx-5 max-[400px]:hidden" xmlns="http://www.w3.org/2000/svg" width="2" height="36" viewBox="0 0 2 36" fill="none">
                                        <path d="M1 0V36" stroke="#E5E7EB" />
                                    </svg>
                                <button className="flex items-center gap-1 rounded-lg border border-primary py-1.5 px-2.5 w-max">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_12657_16865)">
                                            <path d="M8.10326 2.26718C8.47008 1.52393 9.52992 1.52394 9.89674 2.26718L11.4124 5.33818C11.558 5.63332 11.8396 5.83789 12.1653 5.88522L15.5543 6.37768C16.3746 6.49686 16.7021 7.50483 16.1086 8.08337L13.6562 10.4738C13.4205 10.7035 13.313 11.0345 13.3686 11.3589L13.9475 14.7343C14.0877 15.5512 13.2302 16.1742 12.4966 15.7885L9.46534 14.1948C9.17402 14.0417 8.82598 14.0417 8.53466 14.1948L5.5034 15.7885C4.76978 16.1742 3.91235 15.5512 4.05246 14.7343L4.63137 11.3589C4.68701 11.0345 4.57946 10.7035 4.34378 10.4738L1.89144 8.08337C1.29792 7.50483 1.62543 6.49686 2.44565 6.37768L5.8347 5.88522C6.16041 5.83789 6.44197 5.63332 6.58764 5.33818L8.10326 2.26718Z" fill="white" />
                                            <g clipPath="url(#clip1_12657_16865)">
                                                <path d="M8.10326 2.26718C8.47008 1.52393 9.52992 1.52394 9.89674 2.26718L11.4124 5.33818C11.558 5.63332 11.8396 5.83789 12.1653 5.88522L15.5543 6.37768C16.3746 6.49686 16.7021 7.50483 16.1086 8.08337L13.6562 10.4738C13.4205 10.7035 13.313 11.0345 13.3686 11.3589L13.9475 14.7343C14.0877 15.5512 13.2302 16.1742 12.4966 15.7885L9.46534 14.1948C9.17402 14.0417 8.82598 14.0417 8.53466 14.1948L5.5034 15.7885C4.76978 16.1742 3.91235 15.5512 4.05246 14.7343L4.63137 11.3589C4.68701 11.0345 4.57946 10.7035 4.34378 10.4738L1.89144 8.08337C1.29792 7.50483 1.62543 6.49686 2.44565 6.37768L5.8347 5.88522C6.16041 5.83789 6.44197 5.63332 6.58764 5.33818L8.10326 2.26718Z" fill="#FDBF00" />
                                            </g>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_12657_16865">
                                            <rect width="18" height="18" fill="white" />
                                        </clipPath>
                                        <clipPath id="clip1_12657_16865">
                                            <rect width="18" height="18" fill="white" />
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    <span className="text-primary text-sm font-semibold leading-5">Best Seller</span>
                                </button>
                            </div>
                            <div className="flex flex-wrap gap-y-3 sm:gap-x-5 items-center mb-8">
                                <button onClick={() => handleAddToCart(productDetails.id)} className="w-full sm:w-auto py-2 px-5 bg-gradient-to-r from-primary to-secondary rounded-full font-medium text-sm leading-6 text-white transition-all duration-500 hover:bg-transparent">Add to Cart</button>
                            </div>
                            <div className="pro-desc">
                                <h3 className="font-manrope font-bold text-2xl leading-9  mb-3">Description</h3>
                                <p className="font-normal text-base leading-7  mb-8">{productDetails && productDetails.discription}</p>
                                <h4 className="font-manrope font-bold text-xl leading-9  mb-3">What makes our brand different?</h4>
                                <ul className="list-disc list-inside font-normal text-base leading-7">
                                <li>Flexible &amp; Customizable.</li>
                                <li>Efficient and reliable.</li>
                                <li>Quality assurance.</li>
                                <li>30-day return policy.</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ProductDetail;