import React, { useEffect, useState } from 'react'
import LightButton from '../../assets/website/light-mode-button.png';
import DarkButton from '../../assets/website/dark-mode-button.png';

const DarkMode = () => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "light");
  useEffect(() => {
    const element = document.documentElement;
    if(theme === 'dark'){
      element.classList.add("dark");
      localStorage.setItem("theme", "dark");
    }else{
      element.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  return (
    <div className='relative'>
      <img src={LightButton} alt="Light Mode" className={`absolute z-10 right-0 w-12 cursor-pointer drop-shadow-[1px_1px_1px_rgba(0, 0, 0, 0.1)] transition-all duration-300 ${theme === 'dark' ? 'opacity-0' : 'opacity-100'}`} onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')} />
      <img src={DarkButton} alt="Dark Mode" className='w-12 cursor-pointer drop-shadow-[1px_1px_1px_rgba(0, 0, 0, 0.1)] transition-all duration-300' onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')} />
    </div>
  )
}

export default DarkMode