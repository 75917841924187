import React, {useState} from 'react'
import logo from '../../assets/logo.png';
import { IoMdSearch } from "react-icons/io";
import { FaCartShopping } from "react-icons/fa6";
import DarkMode from './DarkMode';
import { FaCaretDown } from "react-icons/fa";
import AddToCart from '../pages/order/AddToCart';
import { Link } from 'react-router-dom';

const suggestions = [
    { title: 'Apple', link: '/search/apple' },
    { title: 'Banana', link: '/search/banana' },
    { title: 'Cherry', link: '/search/cherry' },
    { title: 'Date', link: '/search/date' },
    { title: 'Elderberry', link: '/search/elderberry' },
    { title: 'Fig', link: '/search/fig' },
    { title: 'Grape', link: '/search/grape' },
    { title: 'Honeydew', link: '/search/honeydew' }
];

const Menu = [
    {
        id: 2,
        name: "Top Rated",
        link: "/search/top-rated"
    },{
        id: 3,
        name: "Kids Wear",
        link: "/search/kids-ware"
    },{
        id: 4,
        name: "Mans Wear",
        link: "/search/mans-wear"
    },{
        id: 5,
        name: "Electronics",
        link: "/search/electronics"
    },
];

const dropdownMenu = [
    {
        id: 1,
        name: "Trending Product",
        link: "/search/trending-product"
    },
    {
        id: 2,
        name: "Best Selling",
        link: "/search/best-selling"
    },
    {
        id: 3,
        name: "Top Rated",
        link: "/search/top-rated"
    }
]

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleKeyUp = (e) => {
        const value = e.target.value;
        setQuery(value);
        if(value.length >= 3){
            if (value) {
                const filtered = suggestions.filter(suggestion =>
                    suggestion.title.toLowerCase().includes(value.toLowerCase())
                );
                setFilteredSuggestions(filtered);
            }
        }else{
            setFilteredSuggestions([]);
        }
    };

    const handleClearSearchBox = (e) => {
        setQuery(e.target.text);
        setFilteredSuggestions([]);
    };

    return (
        <div className='shadow-md bg-white dark:bg-gray-900 dark:text-white duration-200 relative z-40'>
            <div className='bg-primary/40 py-2'>
                <div className='container flex justify-between items-center'>
                    <div>
                        <Link to='/' className='font-bold text-2xl sm:text-3xl items-center flex gap-2 uppercase'>
                            <img src={logo} alt='logo' className='w-10' />
                            shopcy
                        </Link>
                    </div>
                    <div className='flex justify-center items-center gap-4'>
                        <div className='relative group hidden sm:block'>
                            <input type="text" value={query} onChange={handleKeyUp} name="" id="" placeholder='Search' className='w-[200px] sm:w-[200px] group-hover:w-[300px] transition-all duration-300 rounded-full border border-gray-300 px-2 py-1 focus:outline-none focus:border-1 focus:border-primary dark:border-gray-500 dark:bg-gray-800' />
                            <IoMdSearch className='text-gray-500 group-hover:text-primary absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer' />
                            {filteredSuggestions.length > 0 ? (
                                <div className="absolute z-10 mt-2 w-full rounded-md bg-white text-black p-2 shadow-lg">
                                    <ul>
                                        {filteredSuggestions.map((suggestion, index) => (
                                        <li key={index} className='p-2 hover:bg-primary/20 cursor-pointer text-center'>
                                            <Link to={suggestion.link} onClick={handleClearSearchBox}>{suggestion.title}</Link>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : ''}
                        </div>
                        <button onClick={toggleSidebar} className='bg-gradient-to-r from-primary to-secondary transition-all duration-200 text-white py-1 px-4 rounded-full flex items-center gap-3 group'>
                            <span className='group-hover:block hidden transition-all duration-200'>Cart</span>
                            <FaCartShopping className='text-xl text-white drop-shadow-sm cursor-pointer' />
                        </button>
                        <div>
                            <DarkMode />
                        </div>
                    </div>
                </div>
            </div>
            <AddToCart isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <div className='flex justify-center'>
                <ul className='sm:flex hidden items-center p-2'>
                    {Menu.map((data) => (
                        <li key={data.id}>
                            <Link to={data.link} className='inline-block px-4 hover:text-primary duration-200'>{data.name}</Link>
                        </li>
                    ))}
                    <li className='group relative cursor-pointer'>
                        <Link className='flex items-center gap-[2px] px-2'>
                            Trending Product
                            <span>
                                <FaCaretDown className='transition-all duration-200 group-hover:rotate-180' />
                            </span>
                        </Link>
                        <div className='absolute z-[9999] hidden group-visited::hidden group-hover:block w-[200px] rounded-md bg-white p-2 text-black shadow-md'>
                            <ul>
                                {dropdownMenu.map((data) => (
                                    <li key={data.id}>
                                        <Link to={data.link} className='inline-block w-full rounded-md p-2 hover:bg-primary/20'>{data.name}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar