import { useEffect } from 'react';
import Home from './components/pages/Home';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Blogs from './components/pages/Blogs';
import Checkout from './components/pages/order/Checkout';
import OrderDetail from './components/pages/order/OrderDetail';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ProductDetail from './components/pages/product/ProductDetail';
import ProductReviews from './components/pages/product/ProductReviews';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import SearchProduct from './components/pages/product/SearchProduct';
import Offers from './components/pages/offers/Offers';
import Products from './components/pages/product/Products';
import Example from './components/pages/product/Example';

const stripePromise = loadStripe('pk_test_51PM4xYETsMhZUIRWMnYtbANCwsviULE9ARcLGcA3gzL7bp11FdCtCLCte3o8M6SzRv9xc0WYz30ss34kZeOsL6Cf00bKAgp2vS');

const App = () => {
  useEffect(() =>{
    AOS.init({
      offset:100,
      duration:800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <div className='App bg-white dark:bg-gray-900 dark:text-white duration-200'>
      <ToastContainer />
      <Elements stripe={stripePromise}>
        <Router>
          <div>
            <Routes>
              <Route path='/' Component={Home} />
              <Route path='/about' Component={About} />
              <Route path='/contact' Component={Contact} />
              <Route path='/blogs' Component={Blogs} />
              <Route path='/checkout' Component={Checkout} />
              <Route path='/order-detail' Component={OrderDetail} />
              <Route path='/products' Component={Products} />
              <Route path='/product/:productId' Component={ProductDetail} />
              <Route path='/product-reviews' Component={ProductReviews} />
              <Route path='/search/:id' Component={SearchProduct}/>
              <Route path='/offers/:id' Component={Offers}/>
            </Routes>
          </div>
        </Router>
      </Elements>
    </div>
  );
}

export default App;
